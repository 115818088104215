"use client";

import Image1 from "/public/assets/features/Group_39.png";
import Image2 from "/public/assets/features/Group_44.png";

import { StaticImageData } from "next/image";

import Image3 from "/public/assets/features/Subtract.png";

import { Key } from "react";
import { useTranslations } from "next-intl";
import ScrollIn from "@/animations/ScrollIn";
import { Link } from "@/i18n/routing";
import { cn } from "@/lib/utils";

import { buttonVariants } from "@/components/ui/button";
import { SectionTitle } from "@/components/ui/SectionTitle";
import Feature from "@/components/Feature";

export default function Features() {
  const t = useTranslations("Landing.Features");

  const features = [
    {
      name: t("features.1.name"),
      description: t("features.1.description"),
      bg: t("features.1.bg"),
      img: Image1,
    },
    {
      name: t("features.2.name"),
      description: t("features.2.description"),
      bg: t("features.2.bg"),
      img: Image2,
    },
    {
      name: t("features.3.name"),
      description: t("features.3.description"),
      bg: t("features.3.bg"),
      img: Image3,
    },
  ];

  return (
    <section className="flex flex-col items-center flex-wrap justify-between gap-8 md:gap-7 mb-32">
      <SectionTitle>{t("title")}</SectionTitle>

      <div className="flex flex-col lg:flex-row gap-3">
        {features.map(
          (
            feature: {
              name: string;
              description: string;
              bg: string;
              img: StaticImageData;
            },
            index: Key | null | undefined
          ) => {
            return index === 1 ? (
              <ScrollIn key={`${feature.name}-scrollin-first`}>
                <div className="flex flex-col gap-6">
                  <Link
                    href="#faq"
                    className={cn(
                      buttonVariants({
                        variant: "default",
                      }),
                      `hidden lg:flex w-full rounded-2xl min-h-[50px]`
                    )}
                  >
                    {t("learnMore")}
                  </Link>
                  <Feature
                    name={feature.name}
                    description={feature.description}
                    bg={feature.bg}
                    img={feature.img}
                  />
                </div>
              </ScrollIn>
            ) : (
              <ScrollIn key={`${feature.name}-scrollin`}>
                <Feature
                  key={`${feature.name}-feature-comp`}
                  name={feature.name}
                  description={feature.description}
                  bg={feature.bg}
                  img={feature.img}
                />
              </ScrollIn>
            );
          }
        )}
        <Link
          href="#faq"
          className={cn(
            buttonVariants({
              variant: "default",
            }),
            "lg:hidden w-full sm:w-1/2 self-center h-[50px] rounded-2xl"
          )}
        >
          {t("learnMore")}
        </Link>
      </div>
    </section>
  );
}
