"use client";

import { SlideRight } from "@/animations/Slide";
import { Input } from "@/components/ui/input";
import { SectionTitle } from "@/components/ui/SectionTitle";
import { Slider } from "@/components/ui/slider";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import { UserStatus } from "@/types/common";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TERM_LIMITS } from "@/lib/constants";
import { roundToDecimal } from "@/lib/utils/decimal/roundToDecimal";
import { cn } from "@/lib/utils";
import { calculateInterestRate } from "@/lib/utils/deposit/calculateInterestRate";

export default function Calculator() {
  const t = useTranslations("Landing.Calculator");
  const [selectedTab, setSelectedTab] = useState<string>(
    t.raw("tabs")[0].value
  );
  const [selectedStatus, setSelectedStatus] = useState<UserStatus>("bronze");
  const [depositAmount, setDepositAmount] = useState(100);
  const [depositTerm, setDepositTerm] = useState(50);
  const [profit, setProfit] = useState(0);

  const calculateProfit = useCallback(() => {
    // It's a calculator with no reinvestments option, so we pass null
    const totalRate = calculateInterestRate(
      depositAmount,
      selectedStatus,
      null
    );
    const dailyProfit = roundToDecimal(depositAmount * (totalRate / 100), 2);
    const totalProfit = dailyProfit * depositTerm;
    setProfit(totalProfit);
  }, [depositAmount, depositTerm, selectedStatus]);

  useEffect(() => {
    calculateProfit();
  }, [calculateProfit]);

  const handleTabClick = (value: string) => {
    setSelectedTab(value);
    const limits = TERM_LIMITS[value];
    setDepositTerm(limits.min);
  };

  const handleDepositAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setDepositAmount(Number(value));
  };

  const handleSliderChange = (value: SetStateAction<number>[]) => {
    setDepositTerm(value[0]);
  };

  return (
    <section className="w-full flex flex-col items-center justify-between gap-8 mb-32">
      <SectionTitle>{t("title")}</SectionTitle>

      <SlideRight className="w-full">
        <section className="flex flex-col gap-8 w-full">
          <div className="grid grid-cols-2 sm:flex gap-2 gap-y-4 items-center">
            {t.raw("tabs").map((tab: any, index: number) => (
              <button
                type="button"
                key={`${tab}-${index}-tab-switch-btn`} // eslint-disable-line react/no-array-index-key
                onClick={() => handleTabClick(tab.value)}
                className={cn(
                  "p-2",
                  selectedTab === tab.value
                    ? " bg-accent rounded-lg"
                    : "dark:text-foreground"
                )}
              >
                {tab.title}
              </button>
            ))}

            <Select
              value={selectedStatus}
              onValueChange={(value: UserStatus) => setSelectedStatus(value)}
            >
              <SelectTrigger
                id="user-status"
                className="w-full sm:w-fit sm:ml-4 bg-block text-foreground"
              >
                <SelectValue placeholder={t("selectStatus")} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="bronze">Bronze</SelectItem>
                <SelectItem value="silver">Silver</SelectItem>
                <SelectItem value="gold">Gold</SelectItem>
                <SelectItem value="platinum">Platinum</SelectItem>
                <SelectItem value="vip">Vip</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-col sm:flex-row gap-8 justify-between">
            <div className="w-full sm:w-1/2 flex flex-col gap-8">
              <div className="flex flex-col gap-4">
                <label className="cursor-pointer" htmlFor="deposit-amount">
                  {t("depositAmountLabel")}
                </label>
                <Input
                  className="border rounded-lg"
                  id="deposit-amount"
                  value={depositAmount}
                  maxLength={15}
                  onChange={handleDepositAmountChange}
                />
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex justify-between items-center">
                  <label htmlFor="replenishment">{t("depositTermLabel")}</label>

                  <div className="dark:text-primary">{depositTerm}</div>
                </div>

                <Slider
                  id="replenishment"
                  className="border border-[rgba(122,140,131,1)] rounded-lg"
                  defaultValue={[depositTerm]}
                  min={TERM_LIMITS[selectedTab].min}
                  max={TERM_LIMITS[selectedTab].max}
                  step={1}
                  value={[depositTerm]}
                  onValueChange={handleSliderChange}
                />
              </div>
            </div>

            <div className="w-full sm:w-1/2 rounded-lg flex flex-col items-center justify-center p-12 gap-[10px]">
              <p>{t("profitMessage")}</p>
              <span className="text-accent text-2xl sm:text-4xl font-bold">
                {profit.toFixed(2)}
                {t("profitUnit")}
              </span>
            </div>
          </div>
        </section>
      </SlideRight>
    </section>
  );
}
