import { motion, MotionValue } from "framer-motion";
import React, { ReactNode } from "react";

interface SlideProps {
  children: ReactNode | MotionValue<number> | MotionValue<string>;
  durationMultiplier?: number;
  className?: string;
}

interface SlideDirectionProps extends SlideProps {
  direction: "left" | "right" | "up" | "down";
}

function SlideLeft({ children, durationMultiplier = 1, ...rest }: SlideProps) {
  const customAnimationDuration = durationMultiplier
    ? 0.5 * durationMultiplier
    : 0.5;
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      transition={{ duration: customAnimationDuration }}
      viewport={{ once: true }}
      variants={{
        visible: { x: 0, opacity: 1 },
        hidden: { x: 100, opacity: 0 },
      }}
      {...rest}
    >
      {children}
    </motion.div>
  );
}

function SlideRight({ children, durationMultiplier = 1, ...rest }: SlideProps) {
  const customAnimationDuration = durationMultiplier
    ? 0.5 * durationMultiplier
    : 0.5;
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      transition={{ duration: customAnimationDuration }}
      viewport={{ once: true }}
      variants={{
        visible: { x: 0, opacity: 1 },
        hidden: { x: -100, opacity: 0 },
      }}
      {...rest}
    >
      {children}
    </motion.div>
  );
}

function SlideUp({ children, durationMultiplier = 1, ...rest }: SlideProps) {
  const customAnimationDuration = durationMultiplier
    ? 0.5 * durationMultiplier
    : 0.5;
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      transition={{ duration: customAnimationDuration }}
      viewport={{ once: true }}
      variants={{
        visible: { y: 0, opacity: 1 },
        hidden: { y: 100, opacity: 0 },
      }}
      {...rest}
    >
      {children}
    </motion.div>
  );
}

function SlideDown({ children, durationMultiplier = 1, ...rest }: SlideProps) {
  const customAnimationDuration = durationMultiplier
    ? 0.5 * durationMultiplier
    : 0.5;
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      transition={{ duration: customAnimationDuration }}
      viewport={{ once: true }}
      variants={{
        visible: { y: 0, opacity: 1 },
        hidden: { y: -100, opacity: 0 },
      }}
      {...rest}
    >
      {children}
    </motion.div>
  );
}

function Slide({
  direction,
  children,
  durationMultiplier = 1,
  ...rest
}: SlideDirectionProps) {
  if (direction === "left") {
    return <SlideLeft {...rest}>{children}</SlideLeft>;
  }
  if (direction === "right") {
    return <SlideRight {...rest}>{children}</SlideRight>;
  }
  if (direction === "up") {
    return <SlideUp {...rest}>{children}</SlideUp>;
  }
  if (direction === "down") {
    return <SlideDown {...rest}>{children}</SlideDown>;
  }
}

export { SlideLeft, SlideRight, SlideUp, SlideDown, Slide };
