import { TranslateFunction } from "@/types";

export function HeroDescription({
  t,
  isDesktop,
}: {
  t: TranslateFunction;
  isDesktop: boolean;
}) {
  return isDesktop ? (
    <div className="flex flex-col">
      <p>{t("description.main1")}</p>
      <p>{t("description.main2")}</p>
    </div>
  ) : (
    <p>{t("description.mobile")}</p>
  );
}
