import { DepositType, TransactionType, UserStatusRank } from "@/types";
import { UserStatus } from "@/types/common";

/**
 * Domains with access to the admin panel
 *
 */
export const SSWhitelistDomains = [
  "https://ss.staging.kroozzle.com",
  "https://ss.staging.kroozzle.com/api",
];

export const USER_STATUSES: UserStatus[] = [
  "bronze",
  "silver",
  "gold",
  "platinum",
  "vip",
];

export const userStatusRanks: UserStatusRank = {
  bronze: 1,
  silver: 2,
  gold: 3,
  platinum: 4,
  vip: 5,
} as const;

export const statusRank = {
  bronze: 0,
  silver: 0.01,
  gold: 0.02,
  platinum: 0.03,
  vip: 0.04,
};

export const MAX_NAME_LENGTH = 32;
export const MIN_NAME_LENGTH = 4;

export const PaymentLimits = {
  deposit: {
    min: 35,
    max: 10000,
  },
  reinvestment: {
    min: 1,
    max: 10000,
  },
  balance: {
    min: 35,
    max: 10000,
  },
  referral_balance_transfer: {
    min: 5,
    max: 100000,
  },
  withdraw: {
    min: 50,
    max: 10000,
  },
};

export const DEPOSIT_TYPES: DepositType[] = [
  "stock",
  "bond",
  "crypto",
  "fund",
] as const;

export const DEPOSIT_THRESHOLDS = {
  bronze: 0,
  silver: 2000,
  gold: 4500,
  platinum: 7500,
  vip: 10000,
};

export const LEVEL_BONUSES = {
  first: 0.05, // 5%
  second: 0.02, // 2%
  third: 0.005, // 0.5%
};

export type TermLimits = {
  [key: string]: { min: number; max: number };
  crypto: { min: number; max: number };
  stock: { min: number; max: number };
  bond: { min: number; max: number };
  fund: { min: number; max: number };
};
export const TERM_LIMITS: TermLimits = {
  crypto: { min: 30, max: 365 },
  stock: { min: 50, max: 365 },
  bond: { min: 180, max: 365 },
  fund: { min: 90, max: 365 },
};

export const TRANSACTION_TYPES: TransactionType[] = [
  "createDeposit",
  "referralBonus",
  "replenishBalance",
  "replenishBalanceFromReferrals",
  "replenishDeposit",
  "withdrawBalance",
  "releaseDeposit",
];

export const statusEmojis: Record<string, string> = {
  bronze: "🥉",
  silver: "🥈",
  gold: "🥇",
  platinum: "💎",
  vip: "🌟",
};

export const OS_TYPES: Record<string, string> = {
  Windows: "Windows",
  Mac: "Mac",
  Linux: "Linux",
};

// TODO: https://deviceatlas.com/blog/list-of-user-agent-strings
// Update this list when new browser types are found
// Add a separate service for decoding user agent strings into readable values
export const BROWSER_TYPES: Record<string, string> = {
  Chrome: "Chrome",
  Firefox: "Firefox",
  Safari: "Safari",
  Edge: "Edge",
  Mozilla: "Mozilla",
};
