/**
 * Checks if a value is a valid number or a string that can be converted to a valid number.
 *
 * @param {any} v - The value to check.
 * @returns {v is number} - Returns true if the value is a valid number or a string that represents a valid number; otherwise, returns false.
 */
const isNumber = (v: any): v is number => {
  return (
    (typeof v === "number" && !Number.isNaN(v)) ||
    (typeof v === "string" && Number.isFinite(+v) && v.trim() !== "")
  );
};

export default isNumber;
