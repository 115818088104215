import { DepositType, TranslateFunction } from "@/types";

import { getInvestmentData } from "@/components/investments/getInvestmentData";
import Image from "next/image";

interface InvestmentCardProps {
  type: DepositType;
  t: TranslateFunction;
}

export function InvestmentCard({ type, t }: InvestmentCardProps) {
  const investmentData = getInvestmentData(type, t);

  return (
    <article
      className={`flex flex-col items-center gap-8 py-8 px-4 border-x border-borderGray`}
    >
      <h3 className="text-xl font-medium">{investmentData.title}</h3>
      <Image
        width={110}
        height={110}
        src={investmentData.img}
        alt={investmentData.title}
        className="h-[110px] max-w-[110px]"
        loading="lazy"
      />
      <p className="text-center">{investmentData.description}</p>
    </article>
  );
}
