import { Cover } from "@/components/ui/cover";
import { TranslateFunction } from "@/types";

export function HeroHeading({ t }: { t: TranslateFunction }) {
  return (
    <h1 className="w-full text-left font-medium text-4xl">
      {/* Visually hidden full sentence for SEO and accessibility */}
      <span className="sr-only">
        Kroozzle – {t("title.subheadline")} {t("title.subheadlineAccent")}
      </span>

      {/* Visible elements for design */}

      <div
        className="flex flex-col md:flex-row w-fit gap-2 md:items-center h-fit"
        aria-hidden="true"
      >
        <span className="w-fit md:whitespace-nowrap">
          {t("title.subheadline")}
        </span>
        <Cover className="w-fit whitespace-nowrap text-accent transition-all duration-300">
          {t("title.subheadlineAccent")}
        </Cover>
      </div>
    </h1>
  );
}
