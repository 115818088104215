import Image, { StaticImageData } from "next/image";

interface FeatureProps {
  name: string;
  description: string;
  bg: string;
  img: StaticImageData;
}

export default function Feature({ name, description, bg, img }: FeatureProps) {
  return (
    <article
      style={{ background: bg }}
      className={`relative h-[240px] md:h-40 lg:h-[300px] leading-5 rounded-[32px] flex flex-col gap-4 px-8 py-8 overflow-hidden`}
    >
      <h2 className="text-xl font-medium">{name}</h2>
      <p className="text-sm sm:text-base">{description}</p>

      <div className="absolute -right-6 -bottom-3 w-[150px] h-[150px] opacity-30">
        <Image src={img} alt={name} style={{ objectFit: "contain" }} />
      </div>
    </article>
  );
}
