"use client";

import { useTranslations } from "next-intl";
import { SlideLeft } from "@/animations/Slide";
import { SectionTitle } from "@/components/ui/SectionTitle";
import { InvestmentCardList } from "@/components/investments/InvestmentCardList/InvestmentCardList";

export default function Investments() {
  const t = useTranslations("Landing.Investments");

  return (
    <section className="flex flex-col items-center justify-between gap-8 mb-32">
      <SectionTitle>{t("titleSection")}</SectionTitle>

      <h2 className="text-[32px] font-medium text-center">{t("headline")}</h2>

      <SlideLeft>
        <div className="grid grid-cols-2 md:grid-cols-4">
          <InvestmentCardList t={t} />
        </div>
      </SlideLeft>
    </section>
  );
}
