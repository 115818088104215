"use client";

import { useTranslations } from "next-intl";
import { SectionTitle } from "@/components/NEW/SectionTitle";

import Image, { StaticImageData } from "next/image";
import Crypto from "/public/assets/images/whatwedo-crypto.png";
import Stocks from "/public/assets/images/whatwedo-stocks.png";
import Bonds from "/public/assets/images/whatwedo-bonds.png";
import Funds from "/public/assets/images/whatwedo-funds.png";
import { Slide } from "@/animations/Slide";

type InvestmentLabel = "crypto" | "stocks" | "bonds" | "funds";

const IMAGES: Record<InvestmentLabel, StaticImageData> = {
  crypto: Crypto,
  stocks: Stocks,
  bonds: Bonds,
  funds: Funds,
};

export default function WhatWeDo() {
  const t = useTranslations("Landing.WhatWeDo");
  const investments: {
    title: string;
    label: InvestmentLabel;
    description: string;
  }[] = t.raw("investments");

  return (
    <section className="flex flex-col gap-8">
      <SectionTitle title={t("title")} subtitle={t("subtitle")} />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {investments.map((investment, index) => {
          const now = new Date().getTime();

          return (
            <Slide
              key={`${investment.label}-${now}`}
              direction={index % 2 === 0 ? "left" : "right"}
            >
              <div className="flex flex-row gap-4 items-center">
                <Image
                  src={IMAGES[investment.label]}
                  alt={investment.title}
                  className="w-40 h-40 md:w-60 md:h-60 select-none pointer-events-none"
                />
                <div className="flex flex-col gap-2 justify-center h-fit max-w-[180px] md:max-w-full text-left text-sm md:text-base">
                  <h2 className="font-bold text-xl">{investment.title}</h2>
                  <p>{investment.description}</p>
                </div>
              </div>
            </Slide>
          );
        })}
      </div>
    </section>
  );
}
