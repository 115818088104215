import StocksImage from "/public/assets/investment-types/stocks.svg";
import BondsImage from "/public/assets/investment-types/bonds.svg";
// import CryptoImage from "/public/assets/investment-types/crypto.svg";
import CryptoImage from "/public/assets/investment-types/orbi-crypto.png";
import FundsImage from "/public/assets/investment-types/funds.svg";

import { DepositType, TranslateFunction } from "@/types";
import { StaticImageData } from "next/image";
import { ElementPosition } from "@/types/common";

interface InvestmentData {
  title: string;
  description: string;
  img: StaticImageData;
  position: ElementPosition;
}

export function getInvestmentData(type: DepositType, t: TranslateFunction) {
  const investmentMap: Record<DepositType, InvestmentData> = {
    stock: {
      title: t("investments.stocks.title"),
      description: t("investments.stocks.description"),
      img: StocksImage,
      position: "top-left",
    },
    bond: {
      title: t("investments.bonds.title"),
      description: t("investments.bonds.description"),
      img: BondsImage,
      position: "top-right",
    },
    crypto: {
      title: t("investments.cryptocurrency.title"),
      description: t("investments.cryptocurrency.description"),
      img: CryptoImage,
      position: "bottom-left",
    },
    fund: {
      title: t("investments.funds.title"),
      description: t("investments.funds.description"),
      img: FundsImage,
      position: "bottom-right",
    },
  };

  return investmentMap[type];
}
