"use client";

import { SectionTitle } from "@/components/NEW/SectionTitle";
import { SlideLeft } from "@/animations/Slide";
import { useLocale, useTranslations } from "next-intl";
import { useMemo } from "react";
import Image, { StaticImageData } from "next/image";
import { cn } from "@/lib/utils";
import image19 from "/public/assets/images/interest-19.png";
import image21 from "/public/assets/images/interest-21.png";
import image24 from "/public/assets/images/interest-24.png";
import image27 from "/public/assets/images/interest-27.png";
import image30 from "/public/assets/images/interest-30.png";

type InterestRateLabel = 19.5 | 21 | 24 | 27 | 30;
type InterestRateDaily = 0.65 | 0.7 | 0.8 | 0.9 | 1;
type InterestRateThreshold = 35 | 100 | 500 | 1000 | 1500;
type ElementPosition = "left" | "right";

const interestRateLabels: InterestRateLabel[] = [19.5, 21, 24, 27, 30];
const interestRateDaily: InterestRateDaily[] = [0.65, 0.7, 0.8, 0.9, 1];
const interestRateThreshold: InterestRateThreshold[] = [
  35, 100, 500, 1000, 1500,
];

type InterestRateOptions = {
  title: string;
  threshold: InterestRateThreshold;
  thresholdPosition: ElementPosition;
  label: InterestRateLabel;
  description: string;
  position: ElementPosition;
  daily: string;
};

const InterestRateImages: Record<InterestRateLabel, StaticImageData> = {
  19.5: image19,
  21: image21,
  24: image24,
  27: image27,
  30: image30,
};

export default function InterestRate() {
  const t = useTranslations("Landing.InterestRate");
  const locale = useLocale();

  const interestRateOptions: InterestRateOptions[] = useMemo(() => {
    return interestRateLabels.map((label, index) => ({
      title: t("options.title"),
      threshold: interestRateThreshold[index],
      thresholdPosition: locale === "tr" ? "left" : "right",
      label,
      description: t("options.description"),
      position: index % 2 === 0 ? "left" : "right",
      daily: t("options.daily", { amount: interestRateDaily[index] }),
    }));
  }, [t]);

  return (
    <section className="flex flex-col w-full self-start gap-4">
      <SectionTitle title={t("title")} subtitle={t("subtitle")} />

      <SlideLeft>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-x-8 items-center">
          {interestRateOptions.map((item, index: number) => {
            const isLastItem = index === interestRateOptions.length - 1;

            return (
              <div
                className={cn(
                  "flex flex-row md:gap-4 items-center w-full justify-between",
                  isLastItem && "md:col-span-2 justify-center",
                  item.position === "right" && "flex-row-reverse"
                )}
                key={`${item.label}-${index}-rate-wrapper`} // eslint-disable-line react/no-array-index-key
              >
                <Image
                  src={InterestRateImages[item.label]}
                  alt={item.description}
                  quality={100}
                  className="select-none pointer-events-none h-[160px] w-[160px] md:w-[200px] md:h-[200px]"
                />
                <div
                  className={cn(
                    "flex flex-col gap-2",
                    item.position === "left"
                      ? "items-end text-right"
                      : "items-start text-left"
                  )}
                >
                  <h2
                    className={cn(
                      "text-xl md:text-2xl w-fit font-bold",
                      item.position === "left" ? "text-right" : "text-left"
                    )}
                  >
                    {item.thresholdPosition === "left" ? (
                      <>
                        <b>${item.threshold}</b> {item.title}
                      </>
                    ) : (
                      <>
                        {item.title} <b>${item.threshold}</b>
                      </>
                    )}
                  </h2>
                  <div className="flex flex-col gap-[2px] text-sm md:text-base">
                    <p>
                      {item.description}{" "}
                      <b className="font-bold">{item.label}%</b>
                    </p>
                    <strong>{item.daily}</strong>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </SlideLeft>
    </section>
  );
}
