import React from "react";

export function SectionTitle({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  return (
    <div
      role="group"
      aria-label="Section Title"
      className="flex flex-col gap-1 w-full items-center justify-center h-fit font-bold"
    >
      <h2 className="text-2xl lg:text-4xl inline-block text-transparent bg-clip-text bg-gold-1">
        {title}
      </h2>
      <p className="text-foreground text-sm lg:text-2xl text-shadow-gray">{subtitle}</p>
    </div>
  );
}
