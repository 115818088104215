import { motion, MotionValue } from "framer-motion";
import { ReactNode, FC } from "react";

interface AnimatedPageProps {
  children: ReactNode | MotionValue<number> | MotionValue<string>;
  durationMultiplier?: number;
  className?: string;
}

const ScaleInView: FC<AnimatedPageProps> = ({
  children,
  durationMultiplier = 1,
  ...rest
}) => {
  const customAnimationDuration = durationMultiplier
    ? 0.5 * durationMultiplier
    : 0.5;
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: customAnimationDuration }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 },
      }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

export default ScaleInView;
