import { InvestmentCard } from "@/components/investments/InvestmentCardList/InvestmentCard";

import { TranslateFunction } from "@/types";
import { DEPOSIT_TYPES } from "@/lib/constants";

interface InvestmentCardsProps {
  t: TranslateFunction;
}

export function InvestmentCardList({ t }: InvestmentCardsProps) {
  return DEPOSIT_TYPES.map((type) => (
    <InvestmentCard key={`${type}-investment-card`} t={t} type={type} />
  ));
}
