"use client";

import Image from "next/image";
import ReferralImg from "/public/assets/referal.svg";
import { SlideRight } from "@/animations/Slide";
import { useTranslations } from "next-intl";
import { SectionTitle } from "@/components/ui/SectionTitle";

export default function Referral() {
  const t = useTranslations("Landing.Referral");

  return (
    <section
      style={{}}
      className="w-full flex flex-col justify-between items-center gap-8 mb-32"
    >
      <SectionTitle>{t("title")}</SectionTitle>

      <SlideRight>
        <div className="w-full flex flex-col-reverse justify-center items-center md:flex-row border rounded-3xl">
          <div className="flex flex-col justify-between gap-4 p-8 md:border-r self-stretch">
            <h2 className="text-[32px] font-medium">{t("headline")}</h2>
            <p>{t("description")}</p>
          </div>
          <Image
            className="min-w-[55%] p-8"
            src={ReferralImg}
            alt={t("imageAlt")}
            loading="lazy"
          />
        </div>
      </SlideRight>
    </section>
  );
}
