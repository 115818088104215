import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import React, { useRef, ReactNode } from "react";

interface ScrollInProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

const ScrollIn: React.FC<ScrollInProps> = ({ children }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    layoutEffect: true,
    offset: ["start end", "start center"],
  });
  const smoothScrollYProgress = useSpring(scrollYProgress, {
    damping: 25,
    stiffness: 100,
  });
  const scale = useTransform(smoothScrollYProgress, [0, 1], [0.5, 1]);
  return (
    <motion.div ref={ref} style={{ scale }}>
      <motion.div
        style={{
          scaleY: scrollYProgress,
        }}
      />
      {children}
    </motion.div>
  );
};

export default ScrollIn;
