import { Transaction } from "@/types";
import { Transaction as DbTransaction } from "@prisma/client";
import { roundToDecimal } from "@/lib/utils/decimal/roundToDecimal";
import { normalizedDecimal } from "@/lib/utils/decimal/normalizedDecimal";
import { UserStatus } from "@/types/common";

/**
 * Calculates the daily interest rate based on the amount.
 *
 * @export
 * @param {number} amount - The amount of money for which the interest rate is to be calculated.
 * @param {UserStatus} userStatus - The user status.
 * @param {OmittedTransaction[]} reinvestments - Array of reinvestment transactions.
 * @returns {number} The daily interest rate as a percentage.
 */

/* eslint-disable no-unused-vars */
type CalculateInterestRate = (
  amount: number,
  userStatus?: UserStatus,
  reinvestments?:
    | Pick<
        Transaction | DbTransaction,
        "id" | "amount" | "transaction_type" | "status"
      >[]
    | null,
) => number;
/* eslint-enable no-unused-vars */

export const calculateInterestRate: CalculateInterestRate = (
  amount,
  userStatus = "bronze",
  reinvestments = null,
) => {
  //* reinvestments are transactions that are related to the same deposit
  let interestRate: number;
  const reinvestmentSum =
    reinvestments
      ?.filter(
        (tx) =>
          tx.transaction_type === "replenishDeposit" && tx.status === "paid",
      )
      .reduce(
        (sum, transaction) => sum + normalizedDecimal(transaction.amount),
        0,
      ) ?? 0;

  const subtotalAmount = amount + reinvestmentSum;

  switch (true) {
    case subtotalAmount >= 35 && subtotalAmount < 100:
      interestRate = 0.65;
      break;
    case subtotalAmount >= 100 && subtotalAmount < 500:
      interestRate = 0.7;
      break;
    case subtotalAmount >= 500 && subtotalAmount < 1000:
      interestRate = 0.8;
      break;
    case subtotalAmount >= 1000 && subtotalAmount < 1500:
      interestRate = 0.9;
      break;
    case subtotalAmount >= 1500:
      interestRate = 1.0;
      break;
    default:
      interestRate = 0; // if the amount is less than 100 USD.
  }

  interestRate = addBonus(interestRate, userStatus);
  return roundToDecimal(interestRate, 3) as number;
};

function addBonus(interestRate: number, userStatus: UserStatus) {
  if (userStatus === "bronze") {
    interestRate += 0; // no bonus
  } else if (userStatus === "silver") {
    interestRate += 0.025;
  } else if (userStatus === "gold") {
    interestRate += 0.05;
  } else if (userStatus === "platinum") {
    interestRate += 0.075;
  } else if (userStatus === "vip") {
    interestRate += 0.1;
  }

  return interestRate;
}
