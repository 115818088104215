import isNumber from "@/lib/utils/isNumber";

/**
 * Rounds a given number to a specified number of decimal places.
 *
 * @param {unknown} value - The value to be rounded. Should be a number.
 * @param {number} decimals - The number of decimal places to round to.
 * @returns {number | any} The rounded number if `value` is a number; otherwise, returns the original value.
 */
export function roundToDecimal(value: any, decimals: number) {
  if (!isNumber(value)) return value;

  const factor = 10 ** decimals;
  return Math.round(value * factor) / factor;
}
