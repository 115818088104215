import { createSharedPathnamesNavigation } from "next-intl/navigation";
import { defineRouting } from "next-intl/routing";

export const locales = ["ru", "en", "tr"];
export type Locale = (typeof locales)[number];

export const defaultLocale = "ru";
export const cookieName = "NEXT_LOCALE";

export const routing = defineRouting({
  locales,
  defaultLocale,
});

export const { Link, redirect, usePathname, useRouter } =
  createSharedPathnamesNavigation({ locales });
