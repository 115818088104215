import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import React from "react";

export function SilverButton({
  className,
  withShadow,
  children,
  ...props
}: {
  className?: string;
  withShadow?: boolean;
  children: React.ReactNode;
  [key: string]: any;
}) {
  return (
    <div
      className={cn(
        "w-fit p-[1px] rounded-lg flex items-center justify-center bg-gradient-to-b from-[#B0C0B8] to-[#444948] hover:brightness-125 transition-all duration-150",
        withShadow && "shadow-grayAccent"
      )}
    >
      <Button
        variant="silverAccent"
        className={cn("border-none", className)}
        {...props}
      >
        {children}
      </Button>
    </div>
  );
}
