"use client";

import Image from "next/image";
import ScaleInView from "@/animations/ScaleInView";
import { SlideRight } from "@/animations/Slide";
import { useTranslations } from "next-intl";
import { SectionTitle } from "@/components/NEW/SectionTitle";
import AstronautReads from "/public/assets/mascot/astronaut-reads.svg";

export function Story() {
  const t = useTranslations("Landing.Story");

  return (
    <section
      id="ourstory"
      // TODO: my-20 is temporary, need to set spacing in the design system
      className="flex flex-col items-center justify-between gap-4 my-20"
    >
      <SectionTitle title={t("title")} subtitle={t("subtitle")} />

      <div className="flex flex-row md:items-end justify-between gap-4 w-full max-w-[80ch]">
        <SlideRight>
          <div className="flex flex-col gap-4 text-left w-full text-sm md:text-base">
            <p>{t("story.paragraph1")}</p>
            <p>{t("story.paragraph2")}</p>
          </div>
        </SlideRight>

        <ScaleInView durationMultiplier={1.5} className="w-fit h-fit">
          <Image
            src={AstronautReads}
            alt="Image of a reading astronaut"
            loading="lazy"
            className="min-w-40 min-h-40 md:w-50 md:h-50 select-none pointer-events-none"
          />
        </ScaleInView>
      </div>

      <div className="flex flex-col gap-4 text-left w-full max-w-[80ch] text-sm md:text-base">
        <p>{t("story.paragraph3")}</p>
        <p>{t("story.paragraph4")}</p>
        <p>{t("story.paragraph5")}</p>
      </div>
    </section>
  );
}
