"use client";

import { SlideLeft, SlideRight } from "@/animations/Slide";
import { useTranslations } from "next-intl";
import { useMediaQuery } from "@/lib/hooks/useMediaQuery";
import { HeroDescription } from "@/components/Hero/HeroDescription";
import { HeroHeading } from "@/components/Hero/HeroHeading";
import { ButtonGroup } from "@/components/NEW/ButtonGroup";
import { SilverButton } from "@/components/NEW/SilverButton";
import { Link } from "@/i18n/routing";

import Image from "next/image";
import AstronautHello from "/public/assets/mascot/astronaut-hello.svg";
import VectorMap from "/public/assets/images/hero-vector-map.png";

export function Hero() {
  const t = useTranslations("Landing.Hero");
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <section className="flex-col gap-4 md:self-start w-full relative">
      <SlideRight>
        <div className="flex flex-col gap-6 w-full">
          <HeroHeading t={t} />
          <Image
            src={AstronautHello}
            alt="Astronaut waves happily"
            height={290}
            width={290}
            className="hidden md:block absolute z-[-1] right-0 top-0"
          />

          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-row gap-4 w-full">
              <HeroDescription t={t} isDesktop={isDesktop} />
              <Image
                src={AstronautHello}
                alt="Astronaut waves happily"
                height={90}
                width={90}
                className="md:hidden"
              />
            </div>
            <p className="w-fit">{t("description.cta")}</p>
          </div>

          <ButtonGroup className="w-full sm:w-fit sm:gap-32">
            <Link href="/signup">
              <SilverButton
                withShadow
                type="button"
                variant="silverAccent"
                // className="px-8 py-2 h-fit font-bold text-base text-foreground"
                aria-label={t("buttons.main")}
              >
                {t("buttons.main")}
              </SilverButton>
            </Link>

            <Link href="#our-story">
              {/* TODO: add text-shadow-gray */}
              <span className="text-foreground underline font-light py-2 px-6 text-base text-shadow-gray">
                {t("buttons.secondary")}
              </span>
            </Link>
          </ButtonGroup>
        </div>
      </SlideRight>
      <SlideLeft>
        <div className="w-full flex items-center justify-center mt-4">
          {/* <Image src={VectorMap} alt="Vector map" height={360} width={360} /> */}
          <Image src={VectorMap} alt="Vector map" sizes="(max-width: 768px) 100vw" quality={90} className="md:max-w-[640px] select-none pointer-events-none"  />
        </div>
      </SlideLeft>
    </section>
  );
}
