import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.5/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.5/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/components/Calculator.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/Features.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/app/src/components/Hero/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/InterestRate.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/investments/Investments.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/NEW/Landing/WhatWeDo.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/Referral.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Story"] */ "/app/src/components/Story.tsx");
