"use client";

import VisibleInView from "@/animations/VisibleInView";
import React from "react";

export function SectionTitle({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <VisibleInView className="flex self-start" durationMultiplier={3}>
      <div className="bg-white px-4 py-1 rounded-2xl self-start flex items-center justify-center">
        <span className="text-[#040707] text-base leading-5">{children}</span>
      </div>
    </VisibleInView>
  );
}
